import React from "react";
import { Link } from "gatsby";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import TwitterLogo from "../../img/twitter.svg";
import LinkedInLogo from "../../img/linkedin.svg";

import useStyles from "./style";
import OpendItem from "./opendItem";

const HeaderNav = () => {
  const classes = useStyles();
  return (
    <Box display="flex" justifyContent="space-between" width="100%" ml={2}>
      <Box className={classes.gridItem}>
        <OpendItem
          name="Advisory"
          items={[
            "Strategy Design & Implementation",
            "Governance & Investor Relations",
            "Stakeholder Engagement",
            "Materiality Review",
            "Impact Assessment",
            "Reporting & Communications",
            "Frameworks & Standards",
            "Training & Capacity Building",
          ]}
        />
      </Box>
      <Box className={classes.gridItem}>
        <OpendItem
          name="Standards"
          items={[
            "AA1000 AccountAbility Principles",
            "AA1000 Stakeholder Engagement Standard",
            "AA1000 Assurance Standard",
            "AA1000 Training",
            "AA1000 Governance",
            "AA1000AS Licensing",
            "AA1000AS Licensed Assurance Providers",
            "AA1000AS Reports",
          ]}
        />
      </Box>
      <Box className={classes.gridItem}>
        <OpendItem name="Insights" items={["The AA Perspective", "AA News"]} />
      </Box>
      <Box className={classes.gridItem}>
        <OpendItem
          name="About"
          items={[
            "Management Team",
            "Advisory Board",
            "Standards Board",
            "Clients",
            "Partners",
            "Offices",
          ]}
        />
      </Box>
      <Box className={classes.gridItem}>
        <Button
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            textTransform: "none",
          }}
          className={classes.dropbtn}
        >
          <Link to={`/careers`} className={classes.navLink}>
            <Typography className={classes.navLinkBold}>Careers</Typography>
          </Link>
        </Button>
      </Box>
      <Box className={classes.gridItem}>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <a
            href="https://twitter.com/AAInsights"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={TwitterLogo}
              alt="twitter logo"
              className={classes.headerIcon}
            />
          </a>
        </IconButton>
        <IconButton edge="start" color="primary" aria-label="menu">
          <a
            href="https://www.linkedin.com/company/138636"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LinkedInLogo}
              alt="linkedin logo"
              className={classes.headerIcon}
            />
          </a>
        </IconButton>
      </Box>
    </Box>
  );
};

export default HeaderNav;
