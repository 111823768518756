import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#459CAD", // filter background
      dark: "#307380", // footer background
      light: "#F7FCFD", // main background
      contrastText: "#F7FCFD",
    },
    secondary: {
      main: "#00BCD4",
      light: "#C8ECF0",
      contrastText: "#F7FCFD",
      divider: "#E5E5E5",
    },
    text: {
      secondary: "white",
    },
  },
  typography: {
    fontFamily: "Arial",
    h1: {
      fontSize: "5rem",
      fontWeight: 300,
    },
    h2: {
      fontSize: "3.75rem",
      fontWeight: 300,
    },
    h6: {
      fontWeight: 550,
      color: "black",
    },
    h4: {
      color: "black",
    },
    subtitle2: {
      color: "#7A7F80",
    },
    h5: {
      fontWeight: 550,
    },
    body: {
      color: "#000",
    },
  },
  customShadows: ["none", "1px 4px 10px #307380", "0px 1px 4px -2px #307380"],
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          overflowX: "hidden",
          backgroundColor: "#F7FCFD",
        },
      },
    },
  },
});

export default theme;
