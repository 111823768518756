import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {
  return {
    container: {
      marginTop: theme.spacing(4),
      backgroundColor: theme.palette.primary.dark,
      minHeight: "590px",
      padding: `${theme.spacing(6)}px ${theme.spacing(13.6)}px ${theme.spacing(4)}px`,
      color: theme.palette.primary.contrastText,
      [theme.breakpoints.only("xs")]: { padding: "2rem" },
      [theme.breakpoints.only("sm")]: { padding: "6rem" },
    },
    subtitle: {
      marginTop: theme.spacing(3),
      color: "white !important",
    },
    boldSubtitle: {
      color: theme.palette.secondary.light,
      fontWeight: theme.typography.fontWeightBold,
    },
    inputContainer: {
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        maxWidth: "none",
      },
    },
    input: {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.dark,
      marginTop: theme.spacing(3),
      borderRadius: 0,
      width: "100%",
      height: "43px",
      "& ::placeholder": {
        color: theme.palette.common.dark,
        opacity: 0.7,
      },
      "& fieldset": {
        border: 0,
        visibility: "hidden",
      },
      "& input": {
        paddingTop: 0,
        paddingBottom: 0,
      },
      "& :-webkit-autofill, & :-webkit-autofill:hover,  & :-webkit-autofill:focus, & :-webkit-autofill:active": {
        WebkitTextFillColor: theme.palette.primary.dark,
      },
    },
    validatedInput: ({ validated }) => ({
      color: validated ? "red" : theme.palette.primary.dark,
    }),
    textAreaContainer: {
      width: "100%",
      "@media (max-width: 661px)": {
        width: "100%",
      },
    },
    textAria: {
      height: "177px",
    },
    arrowDown: {
      pointerEvents: "none",
      position: "absolute",
      right: theme.spacing(2),
    },
    selectOption: {
      color: theme.palette.primary.dark,
    },
    checkboxLabel: {
      marginTop: theme.spacing(2),
    },
    checkbox: {
      color: theme.palette.primary.contrastText,
      "&$checked": {
        color: theme.palette.primary.contrastText,
      },
    },
    checked: {},
    disclaimer: {
      paddingTop: theme.spacing(3.5),
      "& a": {
        color: theme.palette.secondary.main,
        fontWeight: theme.typography.fontWeightBold,
        textDecoration: "none",
      },
      "& a:hover": {
        textDecoration: "underline",
        opacity: 0.9,
        transition: "all 0.5s ease",
      },
    },
    sendButton: {
      width: "100%",
      height: "43px",
      color: theme.palette.primary.main,
      marginTop: theme.spacing(3),
      borderRadius: 0,
      border: `3px solid ${theme.palette.primary.main}`,
      "&:hover": {
        boxShadow: theme.shadows[5],
      },
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        maxWidth: "none",
      },
    },
    linksContainer: {
      [theme.breakpoints.only("xs")]: {
        borderTop: `3px solid ${theme.palette.primary.main}`,
        marginTop: "1rem",
        paddingTop: "1rem",
      },
    },
    link: {
      textAlign: "right",
      paddingTop: theme.spacing(0.8),
      "& a": {
        height: "42px",
        opacity: 1,
        textDecoration: "none",
        color: theme.palette.primary.contrastText,
      },
      "& a img": {
        height: "30px",
      },
      "& a:hover": {
        textDecoration: "underline",
        opacity: 0.9,
        transition: "all 0.5s ease",
      },
    },
    socialIcons: {
      marginTop: theme.spacing(5),
      "& a": {
        margin: "auto 0",
        marginLeft: theme.spacing(3),
      },
    },
    copyRights: {
      paddingTop: theme.spacing(10.5),
      textAlign: "right",
      color: "inherit",
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        textAlign: "left",
        "& span": {
          display: "block",
          marginTop: theme.spacing(4),
        },
      },
      "& a": { color: "inherit" },
    },
  };
});

export default useStyles;
