import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

import Typography from "@material-ui/core/Typography";

import ContactUs from "./ContactUs";
import Links from "./Links";
import useStyles from "./styles";

const Footer = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Grid container justify="space-between">
        <Grid item xs={12} sm={7}>
          <ContactUs />
        </Grid>
        <Grid item xs={12} sm={5}>
          <Links />
        </Grid>
      </Grid>
      <Typography className={classes.copyRights} variant="body1">
        Copyright AccountAbility {new Date().getFullYear()}. All rights reserved.
        <br />
        <span>
          Designed and built by{" "}
          <a href="https://omnified.agency/" target="_blank" rel="noopener noreferrer">
            Omnified
          </a>
          .
        </span>
      </Typography>
    </Box>
  );
};

export default Footer;
