import React from "react";
import "twin.macro";
import { Link, graphql, useStaticQuery } from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";
import MobileHeader from "./mobile";

import AcountabilityLogo from "../../img/logo_text_black.png";

import HeaderNav from "./HeaderNav";
import useStyles from "./style";

const query = graphql`
  query AnnouncementBannerQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "banner" } } }
    ) {
      edges {
        node {
          frontmatter {
            linkText
            link
            on
          }
        }
      }
    }
  }
`;

const getAnnouncementUrl = (str: string) => {
  const loc =
    typeof window === "undefined"
      ? "https://accountability.org"
      : window.location;
  const url = new URL(str, loc);
  return url.pathname;
};

export default function ButtonAppBar() {
  const classes = useStyles();
  const data = useStaticQuery(query);
  const announcement = data.allMarkdownRemark?.edges[0]?.node.frontmatter;

  return (
    <>
      <Hidden smDown>
        {announcement?.on ? (
          <div tw="bg-primary text-white">
            <div tw="max-w-screen-sm mx-auto text-center p-4">
              <Link
                to={getAnnouncementUrl(announcement.link)}
                tw="text-decoration[none] text-inherit"
              >
                <h3 tw="m-0 uppercase leading-relaxed shadow-sm">
                  {announcement.linkText}
                </h3>
              </Link>
            </div>
          </div>
        ) : null}
        <Box className={classes.root} py={2}>
          <AppBar position="sticky" className={classes.header}>
            <Toolbar>
              <Link to="/">
                <img
                  src={AcountabilityLogo}
                  alt="Acountability-Logo"
                  className={classes.headerLogo}
                />
              </Link>
              <HeaderNav />
            </Toolbar>
          </AppBar>
        </Box>
      </Hidden>
      <Hidden mdUp>
        <MobileHeader />
      </Hidden>
    </>
  );
}
