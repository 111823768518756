import React from "react";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

import useStyles from "./style";

const getSlug = (title) =>
  title.replace(/\s+/g, "-").replace("&", "and").toLowerCase();

const CustomRouting = ({ name, item }) => {
  const classes = useStyles();
  if (item === "AA1000AS Licensed Assurance Providers") {
    return (
      <Link
        to="/standards/aa1000-assurance-standard/licensed-assurance-providers"
        className={classes.navLink}
      >
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (item === "AA1000 Licensed Training Providers") {
    return (
      <Link
        to="/standards/licensed-training-providers"
        className={classes.navLink}
      >
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (item === "AA1000AS Licensing") {
    return (
      <a
        className={classes.navLink}
        href="https://elicensing.accountability.org"
      >
        <ListItemText>{item}</ListItemText>
      </a>
    );
  }
  if (item === "AA1000 Governance") {
    return (
      <Link to="/standards/governance" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (item === "AA1000 Training License") {
    return (
      <Link to="/standards/aa1000-training-license" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (item === "AA1000AS Reports") {
    return (
      <Link
        to="/standards/aa1000-assurance-standard/aa1000as-reports"
        className={classes.navLink}
      >
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (name === "Insights") {
    return (
      <Link
        to="/insights#insights"
        className={classes.navLink}
        state={{ selected: item }}
      >
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (name === "About" && item === "Offices") {
    return (
      <Link to="/offices" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (name === "About" && item === "Careers") {
    return (
      <Link to="/careers" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (name === "About" && item === "Partners") {
    return (
      <Link to="/partners" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (name === "About" && item === "Clients") {
    return (
      <Link to="/clients" className={classes.navLink}>
        <ListItemText>{item}</ListItemText>
      </Link>
    );
  }
  if (
    name === "About" &&
    (item === "Advisory Board" ||
      item === "Management Team" ||
      item === "Standards Board")
  ) {
    return (
      <AnchorLink
        to="/about#people"
        className={classes.navLink}
        gatsbyLinkProps={{ state: { selected: item } }}
        stripHash
      >
        <ListItemText>{item}</ListItemText>
      </AnchorLink>
    );
  }
  return (
    <Link
      to={`/${name.toLowerCase()}/${getSlug(item)}`}
      className={classes.navLink}
    >
      <ListItemText>{item}</ListItemText>
    </Link>
  );
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "#C4C4C4",
    "&:focus": {
      backgroundColor: "#C4C4C4",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CustomizedMenus = ({ name, items }) => {
  const classes = useStyles();
  return (
    <div className={classes.dropdown}>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          textTransform: "none",
        }}
        className={classes.dropbtn}
      >
        <Link to={`/${getSlug(name)}`} className={classes.navLink}>
          <Typography className={classes.navLinkBold}>{name}</Typography>
        </Link>
      </Button>
      <div className={classes.dropdownContent}>
        {items.map((item) => {
          return (
            <StyledMenuItem key={item}>
              <CustomRouting name={name} item={item} />
            </StyledMenuItem>
          );
        })}
      </div>
    </div>
  );
};

export default CustomizedMenus;
