import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    background: '#fff',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
  },
  toolBar: {
    padding: 0,
    height: '64px',
  },
  header: {
    boxShadow: 'none',
    backgroundColor: '#fff',
    height: '64px',
  },
  headerIcon: {
    filter: 'brightness(0.6)',
    height: '22px',
  },
  title: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  gridItem: {
    whiteSpace: 'nowrap',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navLinkBold: {
    fontSize: '1.25rem',
    letterSpacing: '0.01em',
  },
  navLink: {
    fontFamily: 'Open Sans sans-serif',
    textDecoration: 'none',
    color: '#04090A',
    '&:link': {
      color: '#04090A',
    },
    '&:visted': {
      color: '#04090A',
    },
  },
  navigation: {
    position: 'relative',
  },
  navigation__background: {
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    position: 'fixed',
    top: '15px',
    right: '15px',
    backgroundImage: 'radial-gradient(#307380, #307380)',
    zIndex: '1000',
    transition: 'transform 0.8s',
  },
  navigation__button: {
    backgroundColor: '#fff',
    height: '40px',
    width: '40px',
    position: 'fixed',
    top: '12px',
    right: '14px',
    borderRadius: '50%',
    zIndex: '1111',
    boxShadow: '0 10px 30px rgba(#000, 0.1)',
    textAlign: 'center',
  },
  navigation__checkbox: {
    display: 'none',
    '&:checked ~ $navigation__background': {
      transform: 'scale(80)',
    },
    '&:checked ~ $navigation__nav': {
      opacity: '1',
      display: 'block',
      width: '100%',
    },
    '&:checked ~ $navigation__button': {
      backgroundColor: 'transparent',
    },
    '&:checked ~ $navigation__button $navigation__icon': {
      backgroundColor: 'transparent',
      '&::after': {
        top: '0',
        backgroundColor: '#fff',
        transform: ' rotate(-135deg)',
      },
      '&::before': {
        top: '0',
        backgroundColor: '#fff',
        transform: ' rotate(135deg)',
      },
    },
  },

  navigation__icon: {
    position: 'relative',
    marginTop: '17px',
    width: '35px',
    height: '3px',
    backgroundColor: '#459CAD',
    display: 'inline-block',
    '&::after': {
      content: '" "',
      position: 'absolute',
      left: '0',
      top: '8px',
      transition: 'all 0.3s',
      width: '35px',
      height: '3px',
      backgroundColor: '#459CAD',
      display: 'inline-block',
    },
    '&::before': {
      content: '" "',
      position: 'absolute',
      left: '0',
      top: '-8px',
      transition: 'all 0.3s',
      width: '35px',
      height: '3px',
      backgroundColor: '#459CAD',
      display: 'inline-block',
    },
  },
  navigation__nav: {
    height: '100vh',
    maxHeight: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: '1011',
    opacity: '0',
    width: '0',
    transition: 'all 0.8s',
  },
  navigation__list: {
    position: 'absolute',
    paddingTop: theme.spacing(4),
    listStyle: 'none',
    textAlign: 'center',
    width: ' 100%',
  },
  navigation__item: {
    margin: '10px',
    paddingLeft: '21px',
  },
  navigation__link: {
    '&:link': {
      display: 'inline-block',
      fontSize: '30px',
      fontWeight: '300',
      color: '#fff',
      padding: ' 10px 0px',
      textDecoration: 'none',
      backgroundImage: 'linear-gradient(120deg, green)',
      transition: 'all 0.3s',
    },
    '&:visited': {
      display: 'inline-block',
      fontSize: '3px',
      fontWeight: '300',
      color: '#fff',
      padding: ' 10px 0px',
      textDecoration: 'none',
      backgroundImage: 'linear-gradient(120deg, transparent 0%, transparent 50%, #fff 50%)',
      backgroundSize: '220%',
      transition: 'all 0.3s',
    },
  },
  headerMobileLogo: {
    height: '32px',
    paddingLeft: '2rem',
  },
  headerLogo: {
    height: '52px',
  },
  dropdown: {
    position: 'relative',
    display: 'inline-block',
    '&:hover $dropdownContent': {
      display: 'block',
    },
  },
  container: {},
  dropdownContent: {
    display: 'none',
    position: 'absolute',
    backgroundColor: '#f1f1f1',
    zIndex: 10,
    minWidth: '160px',
  },
}));

export default useStyles;
