import React from "react";
import { Link } from "gatsby";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import useStyles from "./styles";
import linkedin from "../../img/linkedin.svg";
import twitter from "../../img/twitter.svg";

const CustomizedLink = ({ children, to }) => {
  const classes = useStyles();

  return (
    <Typography variant="h6" className={classes.link}>
      <Link to={to}>{children}</Link>
    </Typography>
  );
};

const links = [
  { path: "/advisory", name: "Advisory" },
  { path: "/standards", name: "Standards" },
  { path: "/insights", name: "Insights" },
  { path: "/clients", name: "Clients" },
  { path: "/partners", name: "Partners" },
  { path: "/about", name: "About" },
  { path: "/careers", name: "Careers" },
  { path: "/privacy-policy", name: "Privacy Policy" },
];

const Links = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justify="flex-end"
      className={classes.linksContainer}
    >
      {links.map(({ path, name }) => (
        <CustomizedLink key={path} to={path}>
          {name}
        </CustomizedLink>
      ))}
      <Grid
        className={`${classes.socialIcons} ${classes.link}`}
        container
        justify="flex-end"
      >
        <a
          href="https://www.linkedin.com/company/138636"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} alt="Linkedin-Logo" />
        </a>
        <a
          href="https://twitter.com/AAInsights"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={twitter} alt="Twitter-Logo" />
        </a>
      </Grid>
    </Grid>
  );
};

export default Links;
