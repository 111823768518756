import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import { Link } from "gatsby";
import useStyles from "./style";

const NavItemforMobile = ({ navItems, setOpen }) => {
  const classes = useStyles();
  const handleClose = () => {
    document.getElementById("navi-toggle").checked = false;
    setOpen(false);
  };
  return (
    <nav className={classes.navigation__nav}>
      <List className={classes.navigation__list}>
        {navItems.map((navItem) => (
          <ListItem
            button
            className={classes.navigation__item}
            key={navItem.name}
          >
            <Link
              to={navItem.link}
              className={classes.navigation__link}
              onClick={handleClose}
            >
              {navItem.name}
            </Link>
          </ListItem>
        ))}
      </List>
    </nav>
  );
};

export default NavItemforMobile;
