import React, { useState } from "react";
import { Link } from "gatsby";

import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import useStyles from "./styles";
import ArrowDown from "../../img/arrowdown.svg";

const regions = [
  "Advisory Services - Americas",
  "Advisory Services - Europe",
  "Advisory Services - Middle East, Africa, and Asia",
  "Standards",
  "Careers",
  "Communications",
];

const ContactUs = () => {
  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
    subject: "",
    subscribe: false,
    jobTitle: "",
  });
  const [sent, setSent] = useState(false);
  const [validated, setValidated] = useState(false);
  const classes = useStyles({ validated });

  const ArrowDownIcon = () => (
    <img className={classes.arrowDown} src={ArrowDown} alt="arrow down" />
  );

  const handleInputChange = (e) => {
    let { value } = e.target;
    if (e.target.name === "subscribe") {
      value = e.target.checked;
    }
    return setState({
      ...state,
      [e.target.name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setValidated(false);
    if (!state.email) {
      setValidated(true);
      return;
    }
    // old
    fetch(
      "https://9i0uto8m01.execute-api.us-west-2.amazonaws.com/Prod/submitForm",
      {
        method: "post",
        body: JSON.stringify({ ...state, subject: state.subject || "General" }),
      }
    )
      .then(() => setSent(true))
      .catch((error) => {
        console.log(error);
        setSent(true);
      });
    // new
    fetch(
      "https://imdve19dy3.execute-api.us-east-2.amazonaws.com/Prod/submitForm",
      {
        method: "post",
        body: JSON.stringify({ ...state, subject: state.subject || "General" }),
      }
    )
      .then(() => setSent(true))
      .catch((error) => {
        console.log(error);
        setSent(true);
      });
  };

  return (
    <form onSubmit={onSubmit}>
      <Typography variant="h2" paragraph>
        Contact us
      </Typography>
      <Typography variant="subtitle1" paragraph>
        Please{" "}
        <Link to="/offices" className={classes.subtitle}>
          find an office
        </Link>{" "}
        closest to you, or get in touch below.
      </Typography>
      {!sent ? (
        <>
          <Grid container spacing={2}>
            <Grid className={classes.inputContainer} item md={6}>
              <OutlinedInput
                type="text"
                placeholder="Name"
                name="name"
                onChange={handleInputChange}
                value={state.name}
                className={classes.input}
                fullWidth
              />
              <OutlinedInput
                type="email"
                placeholder="Email (required)"
                name="email"
                onChange={handleInputChange}
                value={state.email}
                className={`${classes.input} ${classes.validatedInput}`}
                fullWidth
              />
              <OutlinedInput
                type="text"
                placeholder="Company"
                name="company"
                onChange={handleInputChange}
                value={state.company}
                className={classes.input}
                fullWidth
              />
              <OutlinedInput
                type="text"
                placeholder="Job Title"
                name="jobTitle"
                onChange={handleInputChange}
                value={state.jobTitle}
                className={classes.input}
                fullWidth
              />
              <Select
                displayEmpty
                name="subject"
                value={state.subject}
                onChange={handleInputChange}
                IconComponent={ArrowDownIcon}
                input={<OutlinedInput className={classes.input} />}
              >
                <MenuItem value="">
                  <em style={{ opacity: 0.7 }}>Subject</em>
                </MenuItem>
                {regions.map((region) => (
                  <MenuItem
                    key={region}
                    value={region}
                    className={classes.selectOption}
                  >
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid className={classes.textAreaContainer} item md={6}>
              <OutlinedInput
                className={`${classes.input} ${classes.textAria}`}
                placeholder="Your message"
                onChange={handleInputChange}
                value={state.message}
                name="message"
                rows="7"
                multiline
                fullWidth
              />
              <Button className={classes.sendButton} type="submit">
                Send
              </Button>
            </Grid>
          </Grid>
          <FormControlLabel
            className={classes.checkboxLabel}
            control={
              <Checkbox
                checked={state.subscribe}
                onChange={handleInputChange}
                name="subscribe"
                classes={{ root: classes.checkbox, checked: classes.checked }}
              />
            }
            label="I would like to subscribe to the latest AccountAbility Insights"
          />
        </>
      ) : (
        <Typography variant="h4" className={classes.subtitle}>
          Thanks for getting in touch! Someone will get back to you as soon as
          possible.
        </Typography>
      )}
      <Typography className={classes.disclaimer} variant="body1">
        AccountAbility is committed to protecting the privacy of our
        stakeholders’ data and information. To learn more, please review
        our&nbsp;
        <Link to="/privacy-policy">privacy policy.</Link>
      </Typography>
    </form>
  );
};

export default ContactUs;
