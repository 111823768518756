/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { Link } from "gatsby";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NavItemforMobile from "./NavItemforMobile";

import useStyles from "./style";

import Logo from "../../img/logo_text_black.png";

const ModileHeader = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const navItems = [
    { name: "Advisory", link: "/advisory" },
    { name: "Standards", link: "/standards" },
    { name: "Insights", link: "/insights" },
    { name: "About", link: "/about" },
    { name: "Clients", link: "/clients" },
    { name: "Partners", link: "/partners" },
    { name: "Careers", link: "/careers" },
  ];
  return (
    <>
      <AppBar position="fixed" className={classes.header}>
        <Toolbar className={classes.toolBar}>
          <Link to="/">
            <img src={Logo} alt="logo" className={classes.headerMobileLogo} />
          </Link>
          <div className={classes.navigation}>
            <input
              type="checkbox"
              className={classes.navigation__checkbox}
              id="navi-toggle"
              name="navi-toggle"
              onClick={() => setOpen((prev) => !prev)}
            />

            <label htmlFor="navi-toggle" className={classes.navigation__button}>
              <span className={classes.navigation__icon}>&nbsp;</span>
            </label>
            <div className={classes.navigation__background}>&nbsp;</div>

            {open && <NavItemforMobile navItems={navItems} setOpen={setOpen} />}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default ModileHeader;
